import React, { useEffect, useReducer } from 'react'
import firebase from 'firebase/app'
import * as Sentry from '@sentry/browser'
import AuthContext from './context'
import { reducer } from './reducer'
import { initialAuthState } from './state'
import { axios } from '../../lib/axios-client'

export interface Props {
	children?: React.ReactNode
	auth: firebase.auth.Auth
	[key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const AuthProvider = (props: Props) => {
	const { children, auth } = props

	const [state, dispatch] = useReducer(reducer, initialAuthState)

	const login = async (email: string, password: string): Promise<void> => {
		dispatch({ type: 'LOGIN_STARTED' })
		try {
			await auth.signInWithEmailAndPassword(email, password)
		} catch (error: any) {
			Sentry.captureException(error)
			dispatch({ type: 'ERROR', error })
		}
	}

	const logout = async (): Promise<void> => {
		try {
			await auth.signOut()
			dispatch({ type: 'LOGOUT' })
		} catch (error: any) {
			Sentry.captureException(error)
			dispatch({ type: 'ERROR', error })
		}
	}

	useEffect(() => {
		dispatch({ type: 'GET_FIREBASE_USER_STARTED' })
		auth.onAuthStateChanged((user) => {
			if (user) {
				dispatch({ type: 'GET_FIREBASE_USER_FINISHED', currentFirebaseUser: user })
			} else {
				dispatch({ type: 'GET_FIREBASE_USER_FINISHED' })
			}
		})
	}, [auth])

	const fetchAdmin = async () => {
		if (state.currentFirebaseUser) {
			try {
				dispatch({ type: 'FIND_ADMIN_STARTED' })
				const res = await axios.get(`${process.env.REST_ENDPOINT}/admin/api/v1/admins/find`)
				dispatch({
					type: 'FIND_ADMIN_FINISHED',
					currentAdmin: {
						id: res.data.id,
						shop: res.data.shop,
					},
				})
			} catch (error: any) {
				Sentry.captureException(error)
				dispatch({ type: 'ERROR', error })
			}
		}
	}

	useEffect(() => {
		fetchAdmin()
	}, [state.currentFirebaseUser])

	return (
		<AuthContext.Provider
			value={{
				...state,
				login,
				logout,
				fetchAdmin,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthProvider
